import React from 'react'
import { ProgressIndicator } from '@veneer/core'
import * as S from './styles'

const ComponentLoader = () => {
  return (
    <S.Container>
      <ProgressIndicator />
    </S.Container>
  )
}

export default ComponentLoader
