import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: calc(360px - 56px - 52px);
  div {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    position: relative;
    top: calc(50% - 12px);
  }
`
