import React from 'react'
import DevicePolicyWidget from 'src/components/DevicePolicyStatus/devicepolicywidget'

export const DeviceStatus = (props) => {
  return (
    <DevicePolicyWidget
      data-testid="device-policy-widget-component"
      {...props}
    />
  )
}

export default DeviceStatus
