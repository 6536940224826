export const OFFSET = 0
export const LIMIT = -1

export const MAX_LENGTH_DETAILS = 25

export const MIN_WIDTH: number = 577
export const MAX_WIDTH: number = 768

export const DEFAULT_DONUT_WIDTH: number = 200
export const REDUCED_DONUT_WIDTH: number = 90
