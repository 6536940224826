import React from 'react'
import Component from '../components/DevicePolicyStatus/index'
import { Container } from './styles'
import * as T from './types'

const App = ({ properties, ...props }: T.AppPropsType) => {
  return (
    <Container>
      <Component
        data-testid="device-policy-widget-app-component"
        properties={properties}
        {...props}
      />
    </Container>
  )
}

export default App
