import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  IconChevronRight,
  IconClipboard,
  IconPrinter,
  Tooltip
} from '@veneer/core'
import highchartsInterface, {
  Chart,
  ChartProvider
} from '@veneer/highcharts-interface'
import { ThemeProvider } from '@veneer/theme'
import { getAllDevicesFromApi, isServiceCustomer } from 'src/utils/api'
import ComponentLoader from 'src/shared-components/ComponentLoader/index'
import { useShellRootContext } from 'src/contexts/ShellRoot'
import { MAX_LENGTH_DETAILS } from 'src/utils/constants'
import * as S from './styles'
import { donutWidth, donutSize } from 'src/utils/commonMethods'

const DevicePolicyWidget = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const VeneerChart: any = Chart

  const {
    t = (_key: string, defaultValue: string) => defaultValue,
    interfaces
  } = useShellRootContext?.() || {}
  const authProvider = interfaces?.v2?.authProvider
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const criterion = interfaces?.v1?.criterion
  const mock = props?.mock
  const navigation = interfaces?.v1?.navigation
  const properties = props?.properties
  const resolution = props?.resolution
  const useOrgedAuthProvider = properties?.useOrgedAuthProvider
  const [totalDevices, setTotalDevices] = useState<number>(0)
  const [totalPolicies, setTotalPolicies] = useState<number>(0)
  const [compliantDevices, setCompliantDevices] = useState<number>(0)
  const [noncompliantDevices, setNoncompliantDevices] = useState<number>(0)
  const [unknownDevices, setUnknownDevices] = useState<number>(0)
  const [compliancePercentage, setCompliancePercentage] = useState<number>(0)
  const [noncompliancePercantage, setNoncompliancePercentage] =
    useState<number>(0)
  const [unknownPercentage, setUnknownPercentage] = useState<number>(0)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [loader, setLoader] = useState<boolean>(true)

  const handleDonutWidth = useCallback(() => {
    return donutWidth(resolution)
  }, [resolution])
  const handleDonutSize = useCallback(() => {
    return donutSize(resolution)
  }, [resolution])
  const baseAuthProvider = useCallback(() => {
    if (useOrgedAuthProvider) {
      return authProvider.createOrgedAuthProvider()
    } else {
      return authProvider
    }
  }, [authProvider, useOrgedAuthProvider])

  useEffect(() => {
    const getAllDevices = async () => {
      try {
        setLoader(true)

        const response = await getAllDevicesFromApi(
          authStack,
          baseAuthProvider()
        )

        setTotalDevices(Number(response.data.size))

        let policyIds = []

        policyIds = [
          ...response.data.items
            .map((i) => i.policies)
            .filter((i) => i.length !== 0)
            .map((i) => i.map((i) => i.policyId))
        ]

        const uniquePolicyIds = []

        for (const policyId of policyIds) {
          uniquePolicyIds.push(...policyId)
        }

        const uniquePolicyIdsNonNull = [
          ...new Set(uniquePolicyIds.filter((i) => i !== null))
        ]

        setTotalPolicies(uniquePolicyIdsNonNull.length)
        setCompliantDevices(
          response.data.items.filter((i) => i.status === 'compliant').length
        )
        setNoncompliantDevices(
          response.data.items.filter((i) => i.status === 'noncompliant').length
        )
        setUnknownDevices(
          response.data.items.filter((i) => i.status === 'unknown').length
        )
        setCompliancePercentage(
          Math.round(
            (response.data.items.filter((i) => i.status === 'compliant')
              .length /
              response.data.size) *
              100
          ) || 0
        )
        setNoncompliancePercentage(
          Math.round(
            (response.data.items.filter((i) => i.status === 'noncompliant')
              .length /
              response.data.size) *
              100
          ) || 0
        )
        setUnknownPercentage(
          Math.round(
            (response.data.items.filter((i) => i.status === 'unknown').length /
              response.data.size) *
              100
          ) || 0
        )

        const serviceCustomer = await isServiceCustomer(criterion)

        setLoader(false)

        setIsDisabled(
          (useOrgedAuthProvider && !serviceCustomer) ||
            Number(response.data.size) === 0
        )
      } catch (e) {
        // do nothing
      }
    }

    getAllDevices()
  }, [authStack, baseAuthProvider, criterion, useOrgedAuthProvider])

  const mainContent = useMemo(
    () => (
      <S.SecondMainDiv>
        <S.DeviceTitle>
          <S.SubTitle>
            <S.FirstIcon>
              <IconPrinter />
            </S.FirstIcon>

            <S.FirstIconText>{`${totalDevices} ${t(
              'ecp-device-policy-status-widget.devices',
              'DEVICES'
            )}`}</S.FirstIconText>

            <S.Divider></S.Divider>

            <S.SecondIcon>
              <IconClipboard />
            </S.SecondIcon>

            <S.SecondIconText>{`${totalPolicies} ${t(
              'ecp-device-policy-status-widget.Policies',
              'POLICIES'
            )}`}</S.SecondIconText>
          </S.SubTitle>
        </S.DeviceTitle>

        <S.CardBody data-testid="device-policy-card-body-component">
          <S.DonutWrapper
            data-testid="device-policy-donut-wrapper-component"
            className="chartwrapper"
          >
            <ChartProvider
              data-testid="device-policy-chart-provider-component"
              chartInterface={highchartsInterface}
            >
              <VeneerChart
                data-testid="device-policy-chart-component"
                options={{
                  chart: {
                    type: 'donut',
                    width: handleDonutWidth(),
                    height: 149,
                    marginTop: 20,
                    marginBottom: 0
                  },
                  plotOptions: {
                    pie: {
                      startAngle: -90,
                      endAngle: 90,
                      center: ['50%', '75%']
                    }
                  },
                  tooltip: { enabled: totalDevices ? true : false },
                  series: [
                    {
                      size: handleDonutSize(),
                      name: `${t(
                        'ecp-device-policy-status-widget.device',
                        'Device'
                      )}`,
                      data: totalDevices
                        ? [
                            [
                              `${t(
                                'ecp-device-policy-status-widget.compliant',
                                'Compliant'
                              )}`,
                              compliantDevices
                            ],
                            [
                              `${t(
                                'ecp-device-policy-status-widget.nonCompliant',
                                'Noncompliant'
                              )}`,
                              noncompliantDevices
                            ],
                            [
                              `${t(
                                'ecp-device-policy-status-widget.Unknown',
                                'Unknown'
                              )}`,
                              unknownDevices
                            ]
                          ]
                        : ['', 0.1],
                      colors: totalDevices
                        ? ['#1C7A17', '#BE1313', '#737373']
                        : ['#2121210D']
                    }
                  ]
                }}
              />
            </ChartProvider>
          </S.DonutWrapper>

          <S.ParaWrapper data-testid="device-policy-para-wrapper-component">
            <S.MobileSpacer />
            <S.UlWrapper data-testid="device-policy-ul-wrapper-component">
              <S.ListWrapper1 data-testid="device-policy-list-wrapper-one-component" />
              <li>{`${t(
                'ecp-device-policy-status-widget.compliant',
                'Compliant'
              )}: ${compliancePercentage.toFixed(
                2
              )}% (${compliantDevices})`}</li>
              <S.ListWrapper2 data-testid="device-policy-list-wrapper-two-component" />
              <li>{`${t(
                'ecp-device-policy-status-widget.nonCompliant',
                'Noncompliant'
              )}: ${noncompliancePercantage.toFixed(
                2
              )}% (${noncompliantDevices})`}</li>
              <S.ListWrapper3 data-testid="device-policy-list-wrapper-three-component" />
              <li>{`${t(
                'ecp-device-policy-status-widget.Unknown',
                'Unknown'
              )}: ${unknownPercentage.toFixed(2)}% (${unknownDevices})`}</li>
            </S.UlWrapper>
          </S.ParaWrapper>
        </S.CardBody>
      </S.SecondMainDiv>
    ),
    [
      VeneerChart,
      compliancePercentage,
      compliantDevices,
      noncompliancePercantage,
      noncompliantDevices,
      t,
      totalDevices,
      totalPolicies,
      unknownDevices,
      unknownPercentage,
      handleDonutSize,
      handleDonutWidth
    ]
  )

  const renderMainContent = useMemo(() => {
    if (mock) return mainContent
    if (loader) return <ComponentLoader />
    else return mainContent
  }, [loader, mainContent, mock])

  const getContent = (
    <>
      <S.CardHeader data-testid="device-policy-card-header-component">
        <S.Header data-testid="device-policy-header-component">
          {t('ecp-device-policy-status-widget.title', 'Device Policy Status')
            .length > MAX_LENGTH_DETAILS ? (
            <Tooltip
              arrow
              placement="trailing"
              content={`${t(
                'ecp-device-policy-status-widget.title',
                'Device Policy Status'
              )}`}
              id="ssn-tooltip"
            >
              <span>{`${t(
                'ecp-device-policy-status-widget.title',
                'Device Policy Status'
              ).slice(0, MAX_LENGTH_DETAILS)}...`}</span>
            </Tooltip>
          ) : (
            `${t(
              'ecp-device-policy-status-widget.title',
              'Device Policy Status'
            )}`
          )}
        </S.Header>
      </S.CardHeader>

      {renderMainContent}

      <S.Footer>
        <Button
          data-testid="device-policy-footer-button"
          trailingIcon={<IconChevronRight />}
          appearance="ghost"
          onClick={() => navigation?.push?.('policies/devices')}
          disabled={isDisabled}
        >
          {t('ecp-device-policy-status-widget.viewDetails', 'View Details')}
        </Button>
      </S.Footer>
    </>
  )

  return (
    <ThemeProvider density="high">
      <Card
        data-testid="device-policy-get-content-component"
        content={getContent}
      />
    </ThemeProvider>
  )
}

export default DevicePolicyWidget
