import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const SecondMainDiv = styled.div`
  height: 227px;
  padding: ${primitives.layout.size6}px ${primitives.layout.size1}px
    ${primitives.layout.size0}px ${primitives.layout.size1}px;
  overflow: hidden;

  display: block;

  @media (max-width: 376px) {
    height: auto;
  }
`

export const CardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid rgba(33, 33, 33, 0.05);
  padding: 14px ${primitives.layout.size5}px;
`
export const CardBody = styled.div`
  max-width: 350px;
  margin: ${primitives.layout.size0}px auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 376px) {
    flex-direction: column;
  }
`
export const DonutWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-left: ${primitives.layout.size6}px;
  }

  @media (max-width: 376px) {
    margin: ${primitives.layout.size0}px;
  }
`
export const ParaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size0};
  line-height: ${primitives.typography.lineHeight1};
`
export const MobileSpacer = styled.div`
  height: auto;

  width: 0px;

  @media (max-width: 376px) {
    width: 50px;
  }
`
export const UlWrapper = styled.ul`
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    width: auto;
  }
  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    width: auto;
  }
  @media only screen and (min-width: 577px) and (max-width: 750px) {
    width: auto;
  }
  text-align: left;

  p,
  div,
  li {
    margin-bottom: ${primitives.layout.size3}px;
  }

  @media (max-width: 376px) {
    margin-left: 50px;
  }
`
export const ParaTitle = styled.p`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size1};
  font-weight: 400;
  line-height: ${primitives.typography.lineHeight2};
  text-align: left;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #212121;
`
export const ListWrapper1 = styled.div`
  list-style-type: none;
  height: 16px;
  width: 16x;
  min-height: 16px;
  min-width: 16px;
  border-radius: 30%;
  float: left;
  margin-right: 10px;
  background: #1c7a17;
`
export const ListWrapper2 = styled.div`
  list-style-type: none;
  height: 16px;
  width: 16x;
  min-height: 16px;
  min-width: 16px;
  border-radius: 30%;
  float: left;
  margin-right: 10px;
  background: #be1313;
`
export const ListWrapper3 = styled.div`
  list-style-type: none;
  height: 16px;
  width: 16x;
  min-height: 16px;
  min-width: 16px;
  border-radius: 30%;
  float: left;
  margin-right: 10px;
  background: #737373;
`
export const ChartWrapper = styled.div`
  > div:first-child {
    width: 312px;
    height: 312px;
  }
`
export const Header = styled.div`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight4};
`
export const Offlinewrapper = styled.div``
export const DeviceTitle = styled.div`
  padding: ${primitives.layout.size0}px;

  display: flex;
  justify-content: space-around;
  align-items: center;
`
export const SubTitle = styled.div`
  font-weight: 400;
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight2};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #212121;
`
export const FirstIcon = styled.div`
  width: 27px !important;
  height: 27px !important;
  margin-bottom: 9.5px !important;
  display: inline-block !important;
`
export const Divider = styled.div`
  height: 0px;
  border: 1px solid #737373;
`
export const SecondIcon = styled.div`
  margin-top: ${primitives.layout.size2}px !important;
  width: 21px !important;
  height: 30px !important;
  display: inline-block !important;
`
export const FirstIconText = styled.div`
  vertical-align: super;
  display: inline-block;
  margin-left: 6.5px !important;
  margin-bottom: 13px !important;
  font-family: ${primitives.typography.family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight2};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #212121;
`
export const SecondIconText = styled.div`
  height: 20px;
  width: auto;
  margin-top: 13px !important;
  vertical-align: super;
  display: inline-block;
  margin-left: 9.5px;
  font-family: ${primitives.typography.family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight2};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #212121;
`
export const Footer = styled.div`
  height: 52px;
  border-top: 1px solid rgba(33, 33, 33, 0.05);
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
`
