import {
  MAX_WIDTH,
  MIN_WIDTH,
  REDUCED_DONUT_WIDTH,
  DEFAULT_DONUT_WIDTH
} from './constants'

export const donutWidth = (resolution: number) => {
  if (resolution >= MIN_WIDTH && resolution <= MAX_WIDTH) {
    return REDUCED_DONUT_WIDTH
  } else {
    return DEFAULT_DONUT_WIDTH
  }
}

export const donutSize = (resolution: number) => {
  if (resolution >= MIN_WIDTH && resolution <= MAX_WIDTH) {
    return '180%'
  } else {
    return '150%'
  }
}
