/* eslint-disable @typescript-eslint/no-explicit-any */
import { FleetMgtSvcClient } from '@jarvis/web-stratus-client'
import { LIMIT, OFFSET } from './constants'

export const getAllDevicesFromApi = async (stack, auth): Promise<any> => {
  const fleetgtSvcClient = new FleetMgtSvcClient(stack, auth)
  const response = await fleetgtSvcClient.getAllDevices(OFFSET, LIMIT)
  return response
}

export const isServiceCustomer = async (criterion) => {
  const value = await criterion.check({
    type: 'scope',
    value: 'ws-hp.com/ecp/serviceCustomer.READ',
    options: {
      userContext: 'organization'
    }
  })
  return value
}
